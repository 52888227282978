import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./home.scss";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

export default function Home() {
  return (
    <div>
      <div className="home">
        <div className="text-center navb pt-3">
          <img
            className="img-fluid logo"
            src={require("../../assets/img/log.png").default}
            alt=""
          />
        </div>
        <div className="mint text-center">
          <a
            href="https://frostyfriendsclub.io/"
            target="_blank"
            rel="noreferrer"
            className="loading cusbtn"
          >
            Mint Now
          </a>
        </div>
        <div className="showcase mt-5">
          <Container>
            <Row>
              <Col className="rmt-3 col-sm-6 order-sm-1 order-2" lg="6">
                <div className="h-100 d-flex align-items-center">
                  <div className="">
                    <h1 className="title"> What is the Frosty Friends Club?</h1>
                    <p className="subtitle">
                      For years... Frosty Friends were frozen under the
                      Antarctic ice. Global warming caused the ice to melt,
                      freeing 1000 of them to explore the Ethereum blockchain.
                      With over 150+ different features, each Frosty Friend is
                      unique in its own way!
                    </p>
                    <p className="subtitle">
                      Stay connected to the friendliest NFT community ever by
                      joining the Discord below.
                    </p>
                    <div className="mt-2 text-center">
                      <a
                        href="https://discord.gg/s4MN9MczkZ"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          className="mx-3 si"
                          src={require("../../assets/img/discord.png").default}
                          alt=""
                        />
                      </a>
                      <a
                        href="https://twitter.com/FrostyFriendNFT"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          className="mx-3 si"
                          src={require("../../assets/img/twitter.png").default}
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="rmt-3 col-sm-6 order-1 text-center" lg="6">
                <div className="">
                  <img
                    className="img-fluid"
                    src={require("../../assets/img/gif15.gif").default}
                    alt=""
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      <div id="team" className="team">
        <div className="">
          <img
            className="img-fluid w-100"
            src={require("../../assets/img/ic1.png").default}
            alt=""
          />
        </div>
        <div className="mt-5 text-center">
          <h1
            data-aos="fade-up"
            data-aos-duration="2000"
            className="title text-uppercase"
          >
            The Team
          </h1>
        </div>
        <Container className="mmt">
          <Row>
            <Col data-aos="fade-up" data-aos-duration="2000" lg="3">
              <div className="">
                <img
                  className="img-fluid teaming"
                  src={require("../../assets/img/f1.png").default}
                  alt=""
                />
                <div className="text-center mt-3">
                  <h4 className="title text-uppercase">Snow</h4>
                  <p className="font-weight-bold">Director</p>
                </div>
              </div>
            </Col>
            <Col
              data-aos="fade-up"
              data-aos-duration="2000"
              className="rmt-3"
              lg="3"
            >
              <div className="">
                <img
                  className="img-fluid teaming"
                  src={require("../../assets/img/f10.png").default}
                  alt=""
                />
                <div className="text-center mt-3">
                  <h4 className="title text-uppercase">Adam</h4>
                  <p className="font-weight-bold">Marketing</p>
                </div>
              </div>
            </Col>
            <Col
              data-aos="fade-up"
              data-aos-duration="2000"
              className="rmt-3"
              lg="3"
            >
              <div className="">
                <img
                  className="img-fluid teaming"
                  src={require("../../assets/img/logo.png").default}
                  alt=""
                />
                <div className="text-center mt-3">
                  <h4 className="title text-uppercase">MrFrosty</h4>

                  <p className="font-weight-bold">Creator</p>
                </div>
              </div>
            </Col>
            <Col
              data-aos="fade-up"
              data-aos-duration="2000"
              className="rmt-3"
              lg="3"
            >
              <div className="">
                <img
                  className="img-fluid teaming"
                  src={require("../../assets/img/f4.png").default}
                  alt=""
                />
                <div className="text-center mt-3">
                  <h4 className="title text-uppercase">Jonas</h4>
                  <p className="font-weight-bold">Developer</p>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-4 d-flex justify-content-center text-center">
            <Col
              data-aos="fade-up"
              data-aos-duration="2000"
              className="rmt-3"
              lg="3"
            >
              <div className="">
                <img
                  className="img-fluid teaming"
                  src={require("../../assets/img/frostangel.png").default}
                  alt=""
                />
                <div className="text-center mt-3">
                  <h4 className="title text-uppercase">Eliza</h4>
                  <p className="font-weight-bold">Frosty Artist</p>
                </div>
              </div>
            </Col>
            <Col
              data-aos="fade-up"
              data-aos-duration="2000"
              className="rmt-3"
              lg="3"
            >
              <div className="">
                <img
                  className="img-fluid teaming"
                  src={require("../../assets/img/21111.png").default}
                  alt=""
                />
                <div className="text-center mt-3">
                  <h6 className="title text-uppercase">
                    Danny, Michele, Ashen
                  </h6>
                  <p className="font-weight-bold">
                    {" "}
                    Community Management + Marketing
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div id="roadmap" className="roadmap">
        <div className="text-center">
          <h1
            data-aos="fade-up"
            data-aos-duration="2000"
            className="title text-uppercase"
          >
            Roadmap
          </h1>
        </div>
        <Container className="mmt text-center pb-3">
          <Row>
            <Col data-aos="fade-up" data-aos-duration="2000">
              <div className="">
                <h2 className="title ">
                  Phase 1: Giveaways &amp; community building
                </h2>
                <p className="mt-3">
                  We can proudly say we have the friendliest NFT community out
                  there. We need to spread the word and let others know!
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className=" text-center">
            <Col data-aos="fade-up" data-aos-duration="2000">
              <div className="">
                <h2 className="title">Phase 2: Launch</h2>
                <p className=" mt-3">
                  1,000 Frosty Friends will thaw their way out of the ice into
                  the ethereum blockchain
                </p>
              </div>
            </Col>
          </Row>
          <Row className="mmt">
            <Col lg="3" data-aos="fade-up" data-aos-duration="2000">
              <div className="">
                <h3 className="title text-uppercase">At 250 sold,</h3>
              </div>
            </Col>
            <Col lg="9" data-aos="fade-up" data-aos-duration="2000">
              <div className="">
                <img src={require("../../assets/img/ic4.png").default} alt="" />
                <p className="">
                  We host the first official Frosty Friends Club party where a
                  Frosty NFT will be won!
                </p>
              </div>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col lg="3" data-aos="fade-up" data-aos-duration="2000">
              <div className="">
                <h3 className="title text-uppercase">At 500 sold, </h3>
              </div>
            </Col>
            <Col lg="9" data-aos="fade-up" data-aos-duration="2000">
              <div className="">
                <img src={require("../../assets/img/ic4.png").default} alt="" />

                <ul>
                  <li className="">
                    We donate $3,000 to charity (Frosty holders vote)
                  </li>
                  <li>
                    We purchase a plot of land in Decentraland for future
                    events.
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col lg="3" data-aos="fade-up" data-aos-duration="2000">
              <div className="">
                <h3 className="title text-uppercase">At 750 sold, </h3>
              </div>
            </Col>
            <Col lg="9" data-aos="fade-up" data-aos-duration="2000">
              <div className="">
                <img src={require("../../assets/img/ic4.png").default} alt="" />

                <ul>
                  <li className="">We will be donating $5,000 to charity </li>
                  <li className="">
                    Even more virtual land will be purchased and Frosty Club
                    will become Frosty Village.
                  </li>
                  <li className="">Frosty merch store created 🙂</li>
                </ul>
              </div>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col lg="3" data-aos="fade-up" data-aos-duration="2000">
              <div className="">
                <h3 className="title text-uppercase">At 1,000 sold,</h3>
              </div>
            </Col>
            <Col lg="9" data-aos="fade-up" data-aos-duration="2000">
              <div className="">
                <img src={require("../../assets/img/ic4.png").default} alt="" />
                <ul>
                  <li className="">
                    A lucky friend will win their own Decentraland plot!
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="pt-5 text-center pb-3">
          <Row>
            <Col data-aos="fade-up" data-aos-duration="2000">
              <div className="">
                <h2 className="title ">Phase 3: Rewards</h2>
                <p className=" mt-3">
                  Everyone who has a frosty friend in their wallet 7 days after
                  launch will be airdropped twice as many NFT wearables as we
                  originally said. 1 NFT will get you 2 different wearables! We
                  will be releasing sneak peaks in the coming days 😉
                </p>
                <p className="">
                  All Frosty Friends NFTs will serve as mint-passes for future
                  NFT wearables and Projects. Your Frosty will give you lifetime
                  access to these airdrops and rewards as we release them.
                </p>
                <p className="">
                  We will be hosting events every week in Decentraland and
                  Sandbox that will be full of giveaways, live DJ parties, DAO
                  meetings, etc.
                </p>
                <p className="">
                  Our goal is to give back so much to the community that you
                  won't have to even think about selling your Frosty because it
                  provides so much utility.
                </p>
                <p>
                  I can’t express how grateful I am to have you all here as a
                  part of this community. Let’s all lift each other up and hold
                  our position as the friendliest NFT community out there!
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div id="faq" className="faq">
        <img
          className="ic5 img-fluid"
          src={require("../../assets/img/bottom1.png").default}
          alt=""
        />
        <div className="about-content">
          <div className="faq-content">
            <div className="text-center">
              <h1
                data-aos="fade-up"
                data-aos-duration="2000"
                className="ftitle text-uppercase"
              >
                Faq
              </h1>
            </div>
            <Container className="mmt">
              <Row>
                <Col lg="12">
                  <Accordion>
                    <AccordionItem
                      className="bt pt-2 text-left"
                      data-aos="fade-up"
                      data-aos-duration="2000"
                    >
                      <AccordionItemHeading>
                        <AccordionItemButton className="">
                          <h2 className="subtitle">
                            1. Why own a Frosty Friend?
                          </h2>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div>
                          <span className="">
                            All holders will be airdropped a free decentraland
                            wearable to flex in-game!
                          </span>
                          <span className="">
                            &nbsp;Grants access to our exclusive Clubs in
                            Decentraland and The Sandbox Game.
                          </span>
                          <span className="">
                            &nbsp;Get a chance to win your very own plot of land
                            in Decentraland ($35k each)
                          </span>
                          <span className="">
                            &nbsp;Each NFT will make passive income farming
                            $SNOW token.
                          </span>
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem
                      className="bt pt-2 text-left"
                      data-aos="fade-up"
                      data-aos-duration="2000"
                    >
                      <AccordionItemHeading>
                        <AccordionItemButton className="">
                          <h2 className="subtitle">
                            2. What makes your community the best?
                          </h2>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="">
                          We are the friendliest NFT community out there! Our
                          goal is to give back so much value to our holders that
                          they won't have to even think about selling your
                          Frosty because of the utility it provides.
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem
                      className="bt pt-2 text-left"
                      data-aos="fade-up"
                      data-aos-duration="2000"
                    >
                      <AccordionItemHeading>
                        <AccordionItemButton className="">
                          <h2 className="subtitle">
                            3. How much is a Frosty Friend NFT?
                          </h2>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="">
                          All whitelisted members in discord will be able to
                          mint a max of 3 for .03 ETH each. Public sale members
                          will be able to mint a max of 20 per transaction for
                          .05 ETH each.
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem
                      className="bt pt-2 text-left"
                      data-aos="fade-up"
                      data-aos-duration="2000"
                    >
                      <AccordionItemHeading>
                        <AccordionItemButton className="">
                          <h2 className="subtitle">
                            4. How can I mint a Frosty Friend NFT?
                          </h2>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="">
                          Purchase Ethereum on Coinbase, Metamask or an exchange
                          like Binance and link your wallet at the top of this
                          page on our website. Each Frosty will be minted for
                          .05 ETH using the ERC-721 standard.
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem
                      className="bt pt-2 text-left"
                      data-aos="fade-up"
                      data-aos-duration="2000"
                    >
                      <AccordionItemHeading>
                        <AccordionItemButton className="">
                          <h2 className="subtitle">
                            5. What wallets do you support?
                          </h2>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="">
                          We support Metamask and any other wallet compatible
                          with WalletConnect for desktop and mobile.
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem
                      className="bt pt-2 text-left"
                      data-aos="fade-up"
                      data-aos-duration="2000"
                    >
                      <AccordionItemHeading>
                        <AccordionItemButton className="">
                          <h2 className="subtitle">
                            6. When can we mint Frosty Friends NFT?
                          </h2>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="">
                          <span>
                            Pre-sale will take place this sunday, 12/12 at 12 PM
                            EST for everyone who is on the whitelist! Those
                            whitelisted can mint a max of 3 Frosties during this
                            time!
                          </span>
                          <br />
                          <span>
                            Public sale will start 1 hour after, at 1 PM EST!
                            There is no limit to how many you can buy but we do
                            limit each transaction to 20 NFT'S
                          </span>
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>

      <div className="footer">
        <div className="">
          <img
            className="img-fluid w-100"
            src={require("../../assets/img/ic1.png").default}
            alt=""
          />
        </div>
        <Container>
          <Row>
            <Col>
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={require("../../assets/img/nav.png").default}
                  alt=""
                />
                <div className="mt-2 text-center">
                  <a
                    href="https://discord.gg/s4MN9MczkZ
                    "
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="mx-3 si"
                      src={require("../../assets/img/discord.png").default}
                      alt=""
                    />
                  </a>
                  <a
                    href="https://twitter.com/FrostyFriendNFT"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="mx-3 si"
                      src={require("../../assets/img/twitter.png").default}
                      alt=""
                    />
                  </a>
                </div>
                <div className="mt-5 text-center">
                  <p>
                    <span className="font-weight-bold">
                      © {new Date().getFullYear()} Frosty Friends | Original.
                      All rights reserved.
                    </span>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
